<!--suppress ALL -->
<template>
  <div class="">
    <row>
      <column
        class="d-print-none d-none d-md-block"
        md="3">
        <article-related-news
          v-if="!skipRender"
          class="unstyled-list border-top pt-3 position-sticky"/>
      </column>
      <column
        xs="12"
        md="9"
        lg="6"
        class="article-center-column">
        <article-byline/>
        <LazyHydrate
          v-if="skipRender"
          never>
          <div>
            <div class="d-flex global-article-selector">
              <article-body :class="['article-body', bodyStyleClass]"/>
            </div>
          </div>
        </LazyHydrate>
        <article-body
          :class="['article-body', bodyStyleClass]"
          v-else/>
        <article-tags
          v-if="!skipRender"
          :tags="[...secondaryCategories, topic, ...tags]"
          :limit="7"
          class="mb-2">
          <div class="d-flex flex-wrap">
            <AlertButton
              v-if="tagAlertItems.length > 0"
              :items="tagAlertItems"
              :alert-for="alertFor"
              sub-type="tag"
              class="d-print-none mr-3"/>
            <AlertSafeguard
              v-if="tagAlertItems.length > 0"
              class="d-print-none alert-btn-wrapper">
              <a
                class="alert-btn btn btn-secondary"
                href="/alerts">{{ __('manage.alerts') }}</a>
            </AlertSafeguard>
          </div>
        </article-tags>
        <ArticleMailTo
          v-if="!skipRender"
          :subject="title"
          :canonicalUrl="canonicalUrl"
          :emailArticleUrl="emailArticleUrl"
          :emailArticleBody="emailArticleBody"/>
        <article-energi-intelligence
          v-if="!skipRender && companyTickers"
          :tags="tags"/>
        <div
          v-if="!skipRender"
          class="d-block d-lg-none mb-3 text-center">
          <ad slot-id="skyScraperMobile"/>
        </div>
        <div
          v-if="!skipRender"
          class="mb-3 text-center">
          <ad slot-id="sponsoredContentArticle"/>
        </div>
      </column>
      <column
        class="d-print-none d-none d-lg-block"
        md="3"
        lg="3">
        <div class="mb-3 text-center">
          <ad slot-id="skyScraper"/>
        </div>
        <Sticky
          v-if="!skipRender"
          :top="top + 10">
          <div
            class="mb-3 text-center border">
            <ad slot-id="mediumRectangle"/>
          </div>
        </Sticky>
      </column>
    </row>
    <JobCarouselPlaceholder
      v-if="$pubCtx.showJobCarouselInArticlePage"
      :is-placeholder="false"
      :job-carousel-data="$store.state.jobCarousel"/>
    <div
      class="mb-3 mt-3 text-center">
      <ad slot-id="parallax"/>
    </div>
    <row>
      <column
        :lg="recommendedStreamGridValue[1]"
        class="d-print-none d-none d-md-block">
      </column>
      <column
        :lg="recommendedStreamGridValue[0]"
        xs="12"
        md="12"
        class="article-center-column">
        <div
          v-if="$pubCtx.isRecommendationEnabled"
          class="mb-3 mt-5 render-article">
          <div
            data-lp-stream-slot="beta-article"
            data-lp-stream-render-rows="8">
          </div>
        </div>
        <div
          v-else
          class="mb-3">
          <article-trending-today/>
        </div>
        <div class="d-block d-lg-none mb-3 text-center">
          <ad slot-id="mediumRectangleMobile"/>
        </div>
      </column>
      <column
        :lg="recommendedStreamGridValue[1]"
        class="d-print-none d-none d-lg-block">
      </column>
    </row>
  </div>
</template>
<script>

import LazyHydrate from 'vue-lazy-hydration';
import html2Text from 'html-to-text';
import { ArticleComponents, AlertButton, AlertSafeguard, ArticleMailTo, JobCarouselPlaceholder } from 'global-components';

export default {
  name: 'article-default-page',
  components: {
    ArticleMailTo,
    LazyHydrate,
    AlertButton,
    AlertSafeguard,
    ...ArticleComponents,
    JobCarouselPlaceholder
  },
  props: {
    bodyStyleClass: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      ...this.$store.state.article,
      top: 0,
      skipRender: true,
    };
  },
  computed: {
    emailArticleUrl() {
      return this.canonicalUrl + '?utm_source=emailsharing';
    },
    recommendedStreamGridValue() {
      return this.$pubCtx.recommendedStreamGridValue.split(':');
    },
    emailArticleBody() {
      const maxChar = 300;
      const leadText = html2Text.fromString(this.leadText);
      let body = '';
      if (leadText && leadText.length > maxChar) {
        body = html2Text.fromString(this.leadText);
      }
      if (this.body) {
        body = html2Text.fromString(this.body).substr(0, maxChar);
      }
      return body + '...';
    },
    tagAlertItems() {
      let tagItems = [...this.secondaryCategories, this.$store.state.article.topic, ...this.$store.state.article.tags];
      tagItems = tagItems.filter(tag => tag.name !== '').map(tag => {
        tag.name = tag.name.toLowerCase();
        return tag;
      });
      return tagItems.slice(0, 7);
    },
    alertFor() {
      return this.$i18n('these.topics');
    },
    secondaryCategories() {
      return this.categories.filter(category => category.id !== this.mainCategory.id).map(category => {
        category.name = category.label;
        category.subType = 'category';
        return category;
      });
    }
  },
  beforeMount() {
    this.$root.$on('topBarHeight', (topBarHeight) => { this.top = topBarHeight });
  },
  mounted() {
    // window.subPaywallShow gets injected by Zephr if we want to show paywall
    // The code can be found in the component library of Zephr
    this.skipRender = !!window.subPaywallShow;
    console.log(`#GLOBAL-ARTICLE:: showingPaywall= "${this.skipRender}", zephrObject= "${JSON.stringify(window.Zephr)}"`);

    if (!!window.subPaywallShow) {
      const skyScraperElement = document.getElementById('skyScraper');
      const globalArticleSelector = document.querySelector('.global-article-selector');
      const globalArticleSelectorHeight = globalArticleSelector.offsetHeight;
      skyScraperElement.style.marginTop = 'calc(-600px + ' + globalArticleSelectorHeight + 'px + 57px)';
    }
  },
  beforeDestroy() {
    this.$root.$off('topBarHeight');
  },
};
</script>
