<!--suppress ALL -->
<template>
  <div class="wrapper">
    <RestrictedArticleModal
      v-if="isArticleRestricted"
      :id="$store.state.article.id"
      @unRestricted="isArticleRestricted = false"/>
    <div
      v-else
      class="main-article">
      <div class="article-top">
        <row>
          <column
            md="3"
            sm="12">
            <CategoryWidget
              :name="$store.state.article.mainCategory.label"
              :id="$store.state.article.mainCategory.id"
              :url="$store.state.article.mainCategory.url"
              :description="categoryDescription"
              sub-type="category"/>
          </column>
          <column
            md="9"
            sm="12"
            class="article-center-column">
            <row>
              <column
                sm="12"
                md="8"
                class="article-center-column">
                <JobTicker v-if="!hideJobsTicker"/>
              </column>
            </row>
            <row>
              <column
                lg="8"
                md="12"
                class="article-center-column">
                <article-lead-asset class="mb-2 mb-md-3"/>
              </column>
              <column
                md="12"
                lg="4"
                class="article-center-column">
                <article-image-credit class="mb-4 mb-lg-0"/>
              </column>
            </row>
            <row>
              <column
                md="12"
                lg="8"
                class="article-center-column">
                <article-title class="fs-xxl fw-bold mb-4"/>
                <article-lead-text class="fs-lg mb-4"/>
              </column>
            </row>
          </column>
        </row>
      </div>
      <ArticleDefaultPage/>
    </div>
  </div>
</template>
<script>
import { ArticleDefaultPage } from './page-segments/article';
import { getPaywallMeta } from '../server/core/paywallMetadataProcessor';
import { Page, ArticleComponents, JobTicker } from 'global-components';
import { emit } from 'global-hackable';

const buildPropMeta = (property, content, idx) => ({
  property,
  content,
  idx,
});

export default {
  name: 'article-page',
  dataResolver: 'articlePage',
  components: {
    ArticleDefaultPage,
    JobTicker,
    ...ArticleComponents
  },
  extends: Page,
  data() {
    return {
      hideJobsTicker: this.$pubCtx.hideJobsTicker || false,
      categoryDescription: this.$pubCtx[this.$store.state.article.mainCategory.id] &&
        this.$pubCtx[this.$store.state.article.mainCategory.id].categoryDescription,
      isArticleRestricted: this.$store.state.article.isRestricted,
      noIndex: this.$store.state.article.noIndex,
      paid: this.$store.state.article.paid,
      loaded: false,
    };
  },
  computed: {
    mainCategory() {
      return this.$store.state.article.mainCategory;
    },
  },
  async created() {
    await emit('created:ArticlePage', this);
  },
  mounted() {
    if (this.loaded === false) {
      this.loaded = true;
    }
  },
  embed(head) {
    const addMeta = head.meta.bind(head);
    const paywallMeta = getPaywallMeta(this.$store.state);
    addMeta(buildPropMeta('paywall:is_paid', paywallMeta.isPaid));
    addMeta(buildPropMeta('paywall:is_referrer_access', paywallMeta.isReferrerAccess));
    paywallMeta.pids.split(',').map((pid, idx) => addMeta(buildPropMeta('paywall:pids', pid.trim(), idx)));
    addMeta(buildPropMeta('article:section', this.mainCategory.id));
    addMeta(buildPropMeta('article:published_time', this.$store.state.article.publishedAt));
    this.$store.state.article.tags.map((tag, idx) => addMeta(buildPropMeta('article:tag', tag.name, idx)));
    this.$store.state.article.authors.map((author, idx) =>
      addMeta(buildPropMeta('article:author', `${author.name} (${author.username})`, idx)));
    if (this.noIndex && this.paid) {
      head.meta({
        name: 'robots',
        content: 'noindex, noarchive'
      });
    } else if (this.noIndex) {
      head.meta({
        name: 'robots',
        content: 'noindex'
      });
    } else if (this.paid) {
      head.meta({
        name: 'robots',
        content: 'noarchive'
      });
    }
  }
};
</script>
